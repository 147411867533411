// @flow
import createActionType from '../../app/utils/createActionType';

export default {
  AUTHENTICATE: createActionType('user authenticate'),
  LOGOUT: createActionType('user logout'),
  CHANGE_PSSWRD_ON_NEXT_LOGIN: createActionType('user changePsswrdOnNextLogin'),
  SET_PRIVACY_NOTICE_URL: createActionType('set privacyNoticeUrl'),
  SET_AUTHORITY_PROVIDER_CONFIG: createActionType('set authorityProviderConfig')
};
