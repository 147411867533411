import React from "react";

const RedirectPage = () => {
  return (
      <div>
        <p>Authentication successful. Redirecting...</p>
      </div>
  );
};

export default RedirectPage;