import getAssetLink from '../utils/getAssetLink';

export const getDefaultEndpoint = () => {
  const tail = '/rols-back/action';
  if (process.env.NODE_ENV === 'development') {
    return `http://wasrols.rekencentra.be:9080${tail}`;
  }
  return window.location.origin + tail;
};
export const translationsRoute = lang => getAssetLink(`translations/${lang}.json`);
export const iataTZRoute = () => getAssetLink('iata_timezones.json');

const apiRoutes = basePath => ({
  login: () => `${basePath}/login`,
  getOtherLogin: () => `${basePath}/otherlogin`,
  changePassword: () => `${basePath}/changepassword`,
  changePasswordAfterLogin: () => `${basePath}/changepasswordafterlogin`,
  getFlights: () => `${basePath}/flights`,
  getMessages: () => `${basePath}/imcommunicator`,
  checkForNewMessages: () => `${basePath}/impoll`,
  submitMessage: () => `${basePath}/imcommunicator`,
  getOpenItems: () => `${basePath}/openItems`,
  getSystemOpenItems: () => `${basePath}/openItems`,
  getFlightBaggage: () => `${basePath}/communicatorflightbaggage`,
  sendActionRequest: () => `${basePath}/action`,
  readMessage: () => `${basePath}/imread`,
  getRampInstructions: () => `${basePath}/rampInstructions`,
  rampStop: () => `${basePath}/rampStop`,
  rampStart: () => `${basePath}/rampStart`,
  getEngagedPersonnel: () => `${basePath}/engagedPersonnel`,
  getFullImage: () => `${basePath}/fullImage`,
  getQueuedMessages: () => `${basePath}/queuedmessages`,
  getContactList: () => `${basePath}/contactlist`,
  sendvoicecallmessage: () => `${basePath}/voicecallmessage`,
  websocket: () => `${basePath}/socket`,
  getCriticalTimes: () => `${basePath}/criticalTimes`,
  getColors: () => `${basePath}/colordefinitions`,
  getAirports: () => `${basePath}/airports`,
  getFlightCriticalTime: () => `${basePath}/flightcriticaltimes`,
  initData: () => `${basePath}/initData`,
  getLanguageText: () => `${basePath}/languageText`,
  languages: () => `${basePath}/language`,
  about: () => `${basePath}/about`,
  setLanguage: () => `${basePath}/language`,
  getSystemMessages: () => `${basePath}/systemmessages`,
  getLoadSequence: () => `${basePath}/loadsequence`,
  getLoadAudit: () => `${basePath}/loadsequenceaudit`,
  audit2Excel: () => `${basePath}/audit2Excel`,
  doLoadSequenceAction: () => `${basePath}/loadsequenceaction`,
  doFlightUldLockAction: () => `${basePath}/flightuldlockaction`,
  deleteFlight: () => `${basePath}/deleteflight`,
});

export default apiRoutes;
