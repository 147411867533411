import PropTypes from 'prop-types';
import React from 'react';
import {compose} from 'redux';
import connect from 'react-redux-fetch';
import Redirect from 'react-router-dom/Redirect';
import isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import injectApiRoutes from '../../../app/api/injectApiRoutes';
import globals from '../../../app/globals';
import getMessageFromPromise from '../../../app/utils/getMessageFromPromise';
import actions from '../actions';
import selectors from '../selectors';
import Login from '../components/Login';
import settings from '../../settings';
import differenceWithoutFunctionCheck from '../../../app/utils/differenceWithoutFunctionCheck';
import i18next from "i18next";
import convertResourceToObject from "../../../app/utils/convertResourceToObject";
import convertBackendLanguageForFrontend from "../../../app/utils/convertBackendLanguageForFrontend";
import {PublicClientApplication} from "@azure/msal-browser";

function hasAccessToRols(accessFunctions) {
  return accessFunctions.allowAccessRolsCommunicator;
}

function hasAccessToDashboard(accessFunctions) {
  return accessFunctions.allowAccessDashboard;
}

function hasAccessToLoadSequence(accessFunctions) {
  return accessFunctions.allowCommunicatorLoadSequenceMenuFreighterFlights
      || accessFunctions.allowCommunicatorLoadSequenceMenuPassengerFlights;
}

class LoginPage extends React.Component {
  state = {
    username: '',
  };

  componentWillReceiveProps(nextProps) {
    const promise = nextProps.userFetch;
    if (
      !nextProps.isAuthenticated &&
      promise.fulfilled &&
      !isEmpty(differenceWithoutFunctionCheck(nextProps, this.props))
    ) {
      if (hasAccessToRols(promise.value.accessFunctions)
          || hasAccessToDashboard(promise.value.accessFunctions)
          || hasAccessToLoadSequence(promise.value.accessFunctions)
      ) {
        if (this.state.otherLogin) {
          this.setState({
            username: promise.value.userCode,
            otherLogin: false
          });
        }

        const newLanguage = convertBackendLanguageForFrontend(promise.value.language.locale, '_', '-');
        i18next.addResourceBundle(newLanguage, 'translation', convertResourceToObject(promise.value.languageTexts.languageText), true, true);
        i18next.changeLanguage(newLanguage).then(() => {
          this.props.dispatch(settings.actions.setLanguageId(_get(promise, 'language.id')));
          if (!this.isDifferentVersion(promise.value.version)) {
            this.props.dispatch(actions.authenticate(promise.value, this.state.username,
                promise.value.onlineCode,
                hasAccessToDashboard(promise.value.accessFunctions),
                hasAccessToRols(promise.value.accessFunctions),
                hasAccessToLoadSequence(promise.value.accessFunctions),));
            this.props.dispatch(actions.setChangePsswrdOnNextLogin(promise.value.changePasswordOnNextLogin));
          }
        });
      }
    }
  }

  getLoginErrors = () => {
    const promise = this.props.userFetch;
    if (promise.rejected) {
      const msg = getMessageFromPromise(promise) || '';
      if (msg.indexOf(globals.errors.userLocked) !== -1) {
        return ['Please provide valid credentials.'];
      }
      return [msg];
    } else if (promise.fulfilled && (!hasAccessToRols(promise.value.accessFunctions
        || !hasAccessToDashboard(promise.value.accessFunctions)
        || !hasAccessToLoadSequence(promise.value.accessFunctions)))) {
      return ['Please provide valid credentials.'];
    }
    return [];
  };

  handleLogin = (username, pw) => {
    this.setState({
      username,
    });
    this.props.dispatchUserPost(username, pw, undefined, "standardlogin");
  };

  handleOtherLogin = async (config) => {
    this.setState({
      otherLogin: true,
    });

    try {
      const msalInstance = new PublicClientApplication({
        auth: { clientId: config.clientId, authority: config.authorityUrl, redirectUri: config.redirectUriComm },
        system: { allowRedirectInIframe: true },
      });

      msalInstance.handleRedirectPromise()
          .then((response) => {
            if (response && response.account) {
              msalInstance.setActiveAccount(response.account);
              console.log("Login successful:", response);
            }
          })
          .catch((error) => {
            console.error("Error handling redirect:", error);
          });

      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
      }

      const account = msalInstance.getActiveAccount();
      const loginRequest = { scopes: config.scopes };

      const getAuthenticationResult = async (account) => {
        if (account) {
          try {
            return await msalInstance.acquireTokenSilent(loginRequest);
          } catch (error) {
            console.error("Silent token acquisition failed:", error);
            return await msalInstance.acquireTokenPopup(loginRequest)
                .catch((err) => {
                  console.error("Interactive token acquisition failed:", err);
                });
          }
        } else {
          return await msalInstance.loginPopup(loginRequest);
        }
      };

      const authenticationResult = await getAuthenticationResult(account);
      if (!authenticationResult) throw new Error("Authentication failed.");

      this.props.dispatchUserPost(authenticationResult.accessToken, authenticationResult.idToken, config.name, "otherlogin");

    } catch (error) {
      console.error("Login failed:", error);
      this.props.dispatch({ type: "AUTHENTICATE_ERROR", error: error.message });
    }
  };

  isDifferentVersion = (version) => {
    const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
    return version && REACT_APP_VERSION && version!==REACT_APP_VERSION;
  }

  render() {
    const {isAuthenticated, userFetch, privacyNoticeUrl, authorityProviderConfig} = this.props;
    if (userFetch.fulfilled && this.isDifferentVersion(userFetch.value.version)) {
      return (<Redirect to={{pathname: '/restart', state: {referrer: '/login'}}}/>);
    } else if (isAuthenticated) {
      if (userFetch.value.changePasswordOnNextLogin) {
        return (<Redirect to={{pathname: '/changePasswordAfterLogin', state: {referrer: '/login'}}}/>);
      } else if (userFetch.fulfilled && (hasAccessToDashboard(userFetch.value.accessFunctions)
          && !hasAccessToRols(userFetch.value.accessFunctions)
          && !hasAccessToLoadSequence(userFetch.value.accessFunctions)
      )) {
        return (<Redirect to={{pathname: '/dashboard', state: {referrer: '/login'}}}/>);
      }
      return (<Redirect to={{pathname: '/', state: {referrer: '/login'}}}/>);

    } else {
      return (<Login onLogin={this.handleLogin} onOtherLogin={this.handleOtherLogin} privacyNoticeUrl={privacyNoticeUrl} authorityProviderConfig={authorityProviderConfig} errors={this.getLoginErrors()}/>);
    }
  }
}

LoginPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isDashboard: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  dispatchUserPost: PropTypes.func.isRequired,
  userFetch: PropTypes.object.isRequired,
  privacyNoticeUrl: PropTypes.string,
  authorityProviderConfig: PropTypes.array
};

const mapStateToProps = state => ({
  isAuthenticated: selectors.isAuthenticated(state),
  isDashboard: selectors.isDashboard(state),
  isLoadSequence: selectors.isLoadSequence(state),
  isLoadAudit: selectors.isLoadAudit(state),
  privacyNoticeUrl: selectors.getPrivacyNoticeUrl(state),
  authorityProviderConfig: selectors.getAuthorityProviderConfig(state),
});

const mapPropToDispatchToProps = props => [
  {
    resource: "user",
    method: "post",
    request: (usernameOrToken, passwordOrIdToken, configName, loginType) => {
      const loginStrategies = {
        standardlogin: {
          url: props.apiRoutes.login(),
          body: { username: usernameOrToken, password: passwordOrIdToken },
        },
        otherlogin: {
          url: props.apiRoutes.getOtherLogin(),
          body: { accessToken: usernameOrToken, idToken: passwordOrIdToken, authenticationProviderConfigName: configName },
        },
      };

      const loginStrategy = loginStrategies[loginType];

      return {
        url: loginStrategy.url,
        body: loginStrategy.body,
        meta: { suppressError: true },
      };
    },
  },
];

const enhance = compose(
  injectApiRoutes,
  connect(
    mapPropToDispatchToProps,
    mapStateToProps
  )
);

export default enhance(LoginPage);
